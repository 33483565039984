import {
  Button,
  Stack,
  Box,
  HStack,
  Center,
  Text,
  VStack,
  Input,
  PinInput,
  PinInputField,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { schemaOtp } from "../utils/otpValid";
import { linkUsed } from "../utils/link";
import Axios from "axios";

export default function OTPForm({ realOTP, name, email, phone }) {
  const [currentOTP, setCurrentOTP] = useState("");
  const history = useHistory();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [enableRegisteredPage, setEnableRegisteredPage] = useState(false);
  const [isOtpCorrect, setIsOtpCorrect] = useState(false);
  const [alert, setAlert] = useState(false);

  // var url = (window.location != window.parent.location)
  //         ? document.referrer
  //         : document.location.pathname;

  //   let url = document.referrer;
  //   var url2 = new URL(url);

  //   window.addEventListener('message', function(event) {
  //     var origin = event.origin || event.originalEvent.origin; // For Chrome, the origin property is in the event.originalEvent object.
  //     if (origin !== "https://prospect-register.my3dvision.com")
  //         return;
  //     if (typeof event.data == 'object' && event.data.call =='sendValue') {
  //         let now = event.data.value;
  //         console.log(now)
  //         return now;
  //     }
  // }, false);

  var agentCode = localStorage.getItem("agentData");
  if (agentCode === null) {
    agentCode = localStorage.getItem("agentData");
  } else {
    agentCode = localStorage.getItem("agentData");
  }
  console.log("Agent Code yo:", agentCode);

  // window.addEventListener('message', ({ data }) => {
  //   console.log('i got some data!', data); // i got some data! hi!
  // });

  //   window.parent.postMessage({
  //     'func': 'parentFunc',
  //     'message': 'Message text from iframe.'
  // }, "https://my3dvision.com");

  // let targetWindow = window.parent;
  // let urlPath2 = targetWindow.postMessage(message, "https://prospect-register.my3dvision.com")
  // let test = urlPath2.location.pathname;
  // var urlPath = targetWindow.location.pathname;
  // let tempArray = urlPath.split('/');
  // let tempArray = url.split('/');
  // let tempUrl = tempArray[tempArray.length - 1];

  // let periodIndex = tempUrl.indexOf(".");
  // let agentCode = tempUrl.substring(0, periodIndex)


  async function confirmOTP() {
    let date = new Date().toString();

    // await schemaOtp
    //   .validate({
    //     email: email,
    //   })
    //   .catch((err) => {
    //     setError(true);
    //     return null;
    //   })
    //   .then(async (validatedData) => {
    //     if (validatedData !== null && otp === realOTP && isOtpCorrect === true) {
    //       setError(false);
    //       console.log(otp)
    await Axios.post(`${linkUsed()}/registerLeads`, {
      name,
      email,
      phone,
      type: "register",
      date: date,
      // linkHtm: agentCode, open it when go real
      linkHtm: "Agency022"
    })
      .then((res) => {
        if (res.data.message === "error") {
          setError(true);
          return null;
        } else {
          console.log("Done push to Database");
          console.log(agentCode);
          setEnableRegisteredPage(true);
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        setError(true);
        return null;
      });
    //   }
    // });
  }

  return (
    <>
      {enableRegisteredPage ? (
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Application submitted!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            {/* Thank you for your interest for Isola KLCC project. Our sales agent will get back to
              you sonnest possible. */}
            {message}
          </AlertDescription>
        </Alert>
      ) : (
        <>
          {alert ? (
            <HStack zIndex="5" pos="absolute" w="100%" top={0}>
              <Alert status="error" w="100%">
                <AlertIcon />
                <AlertDescription>
                  Please key in the correct OTP.
                </AlertDescription>
              </Alert>
            </HStack>
          ) : null}

          <Center w="100vw" h="100vh" pos="absolute" pointerEvents="initial">
            <VStack
              bgColor={"white"}
              w="23%"
              h="55%"
              borderRadius="10px"
              spacing="62"
              p={5}>
              <Text fontSize="0.8rem">
                *Please check your email to obtain the OTP code
              </Text>
              <Text fontSize="1.5rem" align="center" color="black">
                VERIFY OTP
              </Text>

              <HStack p="3">
                <PinInput
                  onComplete={(e) => {
                    setCurrentOTP(e);
                    console.log(e);
                    console.log(realOTP);
                    if (realOTP === e) {
                      setIsOtpCorrect(true);
                    } else {
                      setIsOtpCorrect(false);
                    }
                  }}
                  size="lg">
                  <PinInputField
                    borderColor="#423028"
                    _hover={{ borderColor: "#ff9d5c" }}
                  />
                  <PinInputField
                    borderColor="#423028"
                    _hover={{ borderColor: "#ff9d5c" }}
                  />
                  <PinInputField
                    borderColor="#423028"
                    _hover={{ borderColor: "#ff9d5c" }}
                  />
                  <PinInputField
                    borderColor="#423028"
                    _hover={{ borderColor: "#ff9d5c" }}
                  />
                </PinInput>
              </HStack>

              <Stack w="100%" align="center">
                <Button
                  fontSize="1.2rem"
                  backgroundColor={"#423028"}
                  cursor="pointer"
                  pointerEvents="initial"
                  _hover={{ background: "#ff9d5c" }}
                  color="white"
                  position="absolute"
                  onClick={() => {
                    if (isOtpCorrect === true) {
                      confirmOTP();
                      // setEnableRegisteredPage(true);
                    } else {
                      setAlert(true);
                    }
                  }}
                  w="180px"
                  h="50px">
                  Submit
                </Button>
              </Stack>
            </VStack>
          </Center>
        </>
      )}
    </>
  );
}
