import React from 'react';
import {
  ChakraProvider,
  theme,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Alert,
  Center
} from '@chakra-ui/react';
import {Switch, Route} from 'react-router-dom'
import RegisterForm from './comps/RegisterForm';
import OTPForm from './comps/OTPForm';

function App() {

  return (
    <ChakraProvider theme={theme}>

    <Switch>

    <Route exact path="/">
      <Center pointerEvents="none"  w="100%" h="100%" pos="fixed" zIndex="3">
      <RegisterForm/>
      </Center>
      </Route>

      <Route path="/otp">
      <Center pointerEvents="none"  w="100%" h="100%" pos="absolute" zIndex="3">
      <OTPForm/>
      </Center>
      </Route>

      </Switch>

    </ChakraProvider>
  );
}

export default App;
